import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import * as XLSX from 'xlsx';
import './FilterPage.css';

const FilterPage = () => {
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    Firstname: '',
    Lastname: '',
    Gender: '',
    Instruments: '',
    //secondaryInstrument: '',
    Vocals: '',
    City: '',
    County: '',
    Country: '',
    Region: '',
    Languages: '',
    Productions: '',
    LastModifiedFrom: '',
    LastModifiedTo: '',
  });

  // Dropdown data
  const [instruments, setInstruments] = useState([]);
  const [genders, setGenders] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [productions, setProductions] = useState([]);
  const [originCountries, setOriginCountries] = useState([]);
  const vocalTypes = ['Tenor', 'Soprano', 'Alto', 'Bass', 'Baritone', 'Mezzo-Soprano', 'Contralto', 'Countertenor', 'Treble', 'Falsetto'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dataRes, instrumentsRes, gendersRes, languagesRes, productionsRes, cOrigins] = await Promise.all([
          axios.post('/api/export/musicians', {}),
          axios.get('/api/instruments'),
          axios.get('/api/gender'),
          axios.get('/api/languages'),
          axios.get('/api/productions'),          
          axios.get('/api/countries'),
        ]);
        setAllData(dataRes.data);
        console.log(dataRes.data);
        setFilteredData(dataRes.data); // Initially, filteredData is the same as allData
        setInstruments(instrumentsRes.data);
        setGenders(gendersRes.data);
        setLanguages(languagesRes.data);
        setProductions(productionsRes.data);
        setOriginCountries(cOrigins.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filters, allData]);

  const applyFilters = () => {
    let data = allData;
    
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        console.log('Applying filter:', key, filters[key]);
  
        if (key === "Instruments" || key === "Languages" || key === "Vocals" || key === "Productions") {
          data = data.filter((item) => {
            const valuesArray = item[key] && item[key].split(',').map(val => val.trim());
            return valuesArray && valuesArray.includes(filters[key]);
          });
        } else if (key === "Gender") {
          data = data.filter((item) => item.Gender === filters.Gender);
        } else if (key === "LastModifiedFrom") {
          const fromDate = new Date(filters.LastModifiedFrom);
          data = data.filter((item) => new Date(item.LastUpdatedOn) >= fromDate);
        } else if (key === "LastModifiedTo") {
          const toDate = new Date(filters.LastModifiedTo);
          data = data.filter((item) => new Date(item.LastUpdatedOn) <= toDate);
        } else if (key === "Region") {
          console.log('filters:', filters.Region);
          data = data.filter((item) => item.Region === filters.Region);
          //data = data.filter((item) => item.OriginCountry === filters.EUOrigin);
        } else {
          data = data.filter((item) => item[key] && item[key].toString().toLowerCase().includes(filters[key].toLowerCase()));
        }
      }
    });
  
    setFilteredData(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleExportToExcel = () => {              
    const headers = [
      "Title",
      "Firstname",
      "Lastname",
      "PostTitle",
      "Gender",
      "Email",
      "Phone1",
      "Phone2",
      "WebURL",
      "Address1",
      "Address2",
      "PostCode",
      "Village",
      "County",
      "Country",
      "OriginCountry",
      "PreferredStyle",
      "Note",
      "LastUpdatedOn",
      "Connections",
      "Instruments",
      "Languages",
      "Productions",
      "Socials",  
      "Region",    
    ]; 
    const formattedData = filteredData.map(row => {
      // Create an object for each row, including only the keys that match your headers
      const rowData = {};
      headers.forEach(header => {
        rowData[header] = row[header] || "";
      });
      return rowData;
    });

    if(formattedData.length === 0) {
    const headerRow = headers.reduce((obj, header) => ({ ...obj, [header]: header }), {});
    formattedData.push(headerRow);
  }    
    const worksheet = XLSX.utils.json_to_sheet(formattedData);   
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Musicians");    
    XLSX.writeFile(workbook, "musicians_data.xlsx");
  };
  

  const renderTableHeader = () => {
    return (
      <tr>
        <th>
        <span className="filter-label">First Name</span>
          <input
            type="text"
            placeholder="First Name"
            name="Firstname"
            value={filters.Firstname}
            onChange={handleChange}
            className="filter-input"
          />
        </th>
        <th>
        <span className="filter-label">Last Name</span>
          <input
            type="text"
            placeholder="Last Name"
            name="Lastname"
            value={filters.Lastname}
            onChange={handleChange}
            className="filter-input"
          />
        </th>
        <th>
        <span className="filter-label">Gender</span>
          <select name="Gender" value={filters.Gender} onChange={handleChange} className="filter-select">
            <option value="">All</option>
            {genders.map((gender) => (
              <option key={gender.ID} value={gender.Sex}>{gender.Sex}</option>
            ))}
          </select>
        </th>
        <th>
        <span className="filter-label">Instrument</span>
          <select name="Instruments" value={filters.Instruments} onChange={handleChange} className="filter-select">
            <option value="">All</option>
            {instruments.map((instrument) => (
              <option key={instrument.ID} value={instrument.InstrumentName}>{instrument.InstrumentName}</option>
            ))}
          </select>
        </th>
        
        <th>
        <span className="filter-label">Vocal Type</span>
          <select name="Vocals" value={filters.Vocals} onChange={handleChange} className="filter-select">
            <option value="">All</option>
            {vocalTypes.map((vocalType) => (
              <option key={vocalType} value={vocalType}>{vocalType}</option>
            ))}
          </select>
        </th>
        
        <th>
        <span className="filter-label">County</span>
          <input
            type="text"
            placeholder="County"
            name="County"
            value={filters.County}
            onChange={handleChange}
            className="filter-input"
          />
        </th>
        <th>
        <span className="filter-label">Country</span>
          <input
            type="text"
            placeholder="Country"
            name="Country"
            value={filters.Country}
            onChange={handleChange}
            className="filter-input"
          />
        </th>
        <th>
          <span className="filter-label">EU Origin</span>
          <select name="Region" value={filters.Region} onChange={handleChange} className="filter-select">
            <option value="">All</option>
            <option value="EU">EU</option>
            <option value="Non-EU">Non-EU</option>
          </select>         
        </th>
                  
        <th>
        <span className="filter-label">Language</span>
          <select name="Languages" value={filters.Languages} onChange={handleChange} className="filter-select">
            <option value="">All</option>
            {languages.map((lang) => (
              <option key={lang.ID} value={lang.Language}>{lang.Language}</option>
            ))}
          </select>
        </th>
        <th>
        <span className="filter-label">Production</span>
          <select name="Productions" value={filters.Productions} onChange={handleChange} className="filter-select">
            <option value="">All</option>
            {productions.map((prod) => (
              <option key={prod.ID} value={`${prod.Name} - ${prod.Location} : ${prod.Year} - ${prod.Month}`}>{`${prod.Name} - ${prod.Location} : ${prod.Year} - ${prod.Month}`}</option>
            ))}
          </select>
        </th>
        <th>
        <span className="filter-label">Modified From</span>
          <input
            type="date"
            name="LastModifiedFrom"
            value={filters.LastModifiedFrom}
            onChange={handleChange}
            className="filter-input"
          />
        </th>
        <th>
        <span className="filter-label">Modified To</span>
          <input
            type="date"
            name="LastModifiedTo"
            value={filters.LastModifiedTo}
            onChange={handleChange}
            className="filter-input"
          />
        </th>
      </tr>
    );
  };

  
  return (
    <div className="musicians-filter-container">
      <h1>Musician Filter</h1>
      
      <div className="actions">
        <button className="export-button" onClick={handleExportToExcel}>Export to Excel</button>
      </div>
      <div className="fetched-data">
        <table>
        <thead>
            {renderTableHeader()}
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr key={index}>
                {/* Ensure these keys match your data structure */}
                <td>{row.Firstname}</td>
                <td>{row.Lastname}</td>
                <td>{row.Gender}</td>
                <td>{row.Instruments}</td>                
                <td>{row.Vocals}</td>                
                <td>{row.County}</td>
                <td>{row.Country}</td>
                <td>{row.Region}</td>
                <td>{row.Languages}</td>
                <td>{row.Productions}</td>
                <td>{row.LastUpdatedOn}</td>                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FilterPage;
