import React, { useState } from 'react';
import useSearch from '../useSearch';
import SearchResults from '../SearchResults';
import AddElements from '../AddElements';

const Socials = () => {
  const [socialMedia, setSocialMedia] = useState({ url: '', icon: '' });
  const { results, setResults: setSearchResults, errorMsg, setErrorMsg: setSearchError, handleSearch } = useSearch();
  const {addresult, setResults, adderror, setErrorMsg, handleAddElement } = AddElements();
  // Handlers would go here

  return (
    <div className="section social-media">      
      <input type="text" placeholder="URL" value={socialMedia.url} onChange={(e) => setSocialMedia({ ...socialMedia, url: e.target.value })} />
      <select value={socialMedia.icon} onChange={(e) => setSocialMedia({ ...socialMedia, icon: e.target.value })}>
        <option value="">Select Platform</option>
        <option value="Facebook">Facebook</option>
        <option value="Instagram">Instagram</option>
        <option value="YouTube">YouTube</option>
        <option value="LinkedIn">LinkedIn</option>
        <option value="Twitter (X)">Twitter (X)</option>
        <option value="Threads">Threads</option>
        <option value="SnapChat">SnapChat</option>
        <option value="TikTok">TikTok</option>
      </select>
      <button onClick={() => {
        setResults(null);
        setErrorMsg(null);
        handleSearch(socialMedia, 'socials');
      }}>Search</button>
      <button onClick={() => {
        setSearchResults(null);
        setSearchError(null);
        handleAddElement(socialMedia, 'socials');
      }}>Add</button>
      {addresult && <p className='add-result'>Social Media account details successfully added</p>}
      {adderror && <p className='add-error'>{adderror}</p>}

      {results && <SearchResults results={results} searchTable={'socials'} setResults={setSearchResults} />}
      {errorMsg && <p className='search-error'>{errorMsg}</p>}

    </div>
  );
};

export default Socials;