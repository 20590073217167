import React, { useState } from 'react';
import axios from '../axiosConfig';

// Define styles similar to FilterPage
const containerStyle = {
  maxWidth: '500px',
  margin: '40px auto',
  padding: '40px',
  backgroundColor: '#fff',
  borderRadius: '10px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
};

const inputStyle = {
  padding: '12px',
  borderRadius: '5px',
  border: '1px solid #ccc',
  fontSize: '16px',
  marginBottom: '20px', // Add some space between the inputs
};

const buttonStyle = {
  padding: '12px 25px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '16px',
  transition: 'background-color 0.3s ease',
  backgroundColor: '#4CAF50',
  color: 'white',
};

const SignUp = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // New state for error messages
    const [RegSuccess, setRegSuccess] = useState(false);
    const [Loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Clear previous error messages
        try {
            setLoading(true);
            const response = await axios.post('/api/register', {
                username,
                email,
                password
            });
            console.log('Registration successful', response.data);
            setRegSuccess(true);
            // Redirect or show a success message
            // Example: Redirect to login page
            //window.location = '/login';
        } catch (error) {
            setErrorMessage(error.response.data.message || 'Registration failed due to an unexpected error.');
        }
        setLoading(false);
    };

    if (Loading) {
        return (
            <div style={containerStyle}>
                <h2>Signing Up...</h2>
            </div>
        );
    }

    if (RegSuccess) {
        return (
            <div style={containerStyle}>
                <h2>Registration successful</h2>
                <p>Please wait for the administrator to approve your registration.</p>
                <p><a href="/login">Login</a></p>
            </div>
        );
    }

    return (
        <div style={containerStyle}>
            <form onSubmit={handleSubmit}>
                <h2>Sign Up</h2>
                <input
                    type="text"
                    placeholder="Username"
                    style={inputStyle}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Email"
                    style={inputStyle}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    style={inputStyle}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} {/* Display error message */}
                <button type="submit" style={buttonStyle}>Sign Up</button>
            </form>
        </div>
    );
};

export default SignUp;
