import React, { useState } from 'react';
import axios from '../axiosConfig';
import { Link } from 'react-router-dom';

const containerStyle = {
    maxWidth: '500px',
    margin: '40px auto',
    padding: '40px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  };
  
  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  };
  
  const inputStyle = {
    padding: '12px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '16px',
  };
  
  const buttonStyle = {
    padding: '12px 25px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
    width: '100%',
    backgroundColor: '#4CAF50',
    color: 'white',
  };

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [statusMessage, setStatusMessage] = useState(''); // State to hold login status message

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('api/login', { username, password });
            console.log('Login successful', response.data);
            if (response.data.message === 'Account is not activated. Please ask the administrator to activate your account.') {
                setStatusMessage(response.data.message);                
            } else {
              localStorage.setItem('userToken', response.data.token);
              window.location = '/';
            }
        } catch (error) {
            // Display error message from response or default message
            setStatusMessage(error.response?.data || 'Login failed due to an unexpected error.');
        }
    };

    return (
        <div className="login-container" style={containerStyle}>
            <form onSubmit={handleSubmit} style={formStyle}>
                <h2>Login</h2>
                <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} style={inputStyle} />
                <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} style={inputStyle} />
                <button type="submit" style={buttonStyle}>Login</button>
                {statusMessage && <p style={{ color: 'red' }}>{statusMessage}</p>} {/* Display the status message here */}
                <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>
            </form>
        </div>
    );
};

export default Login;


