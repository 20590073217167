import React, { useState } from 'react';
import useSearch from '../useSearch';
import SearchResults from '../SearchResults';
import AddElements from '../AddElements';

const Instruments = () => {
    const [instrumentName, setInstrumentName] = useState('');     
    const { results, setResults: setSearchResults, errorMsg, setErrorMsg: setSearchError, handleSearch } = useSearch();
    const {addresult, setResults, adderror, setErrorMsg, handleAddElement } = AddElements();
    
    return (
      <div className="instruments-section">
        <input
          type="text"
          value={instrumentName.instrumentName}
          onChange={(e) => setInstrumentName({instrumentName: e.target.value})}
          placeholder="Enter instrument name"
        />
        <button onClick={() => {
                                setResults(null);
                                setErrorMsg(null);
                                handleSearch(instrumentName, 'instruments');                              
                                }}>Search</button>
        <button onClick={() => {
                                setSearchResults(null);
                                setSearchError(null);
                                handleAddElement(instrumentName, 'instruments');                                
                                }} >Add</button>
        {addresult && <p className='add-result'>Instrument successfully added</p>}
        {adderror && <p className='add-error'>{adderror}</p>}

        {results && <SearchResults results={results} searchTable={'instruments'} setResults={setSearchResults} />}
        {errorMsg && <p className='search-error'>{errorMsg}</p>}
        {/*searchResult && (
          <div>
            <button onClick={() => handleEditInstrument(searchResult.ID)}>Edit</button>
            <button onClick={() => handleDeleteInstrument(searchResult.ID)}>Delete</button>
          </div>
        )}
        <button onClick={handleAddInstrument}>Add</button>
        */}
        


      </div>
    );
};

export default Instruments;