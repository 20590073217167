import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();

    const handleSignOut = () => {
        localStorage.removeItem('userToken'); // Remove the stored token
        navigate('/login'); // Redirect to login page
    };

    return (
        <div className="header">
            {/* Add conditional rendering based on authentication status */}
            {localStorage.getItem('userToken') && (
                <button onClick={handleSignOut} style={{ float: 'right' }}>Sign Out</button>
            )}
        </div>
    );
};

export default Header;