import React, { useState } from 'react';
import AddElements from '../AddElements';


const Credentials = () => {
  const [credentials, setCredentials] = useState({ EmailServer: '', UserID: '', Password: '', FromName: '' });  
  const {addresult,  adderror,  handleAddElement } = AddElements();
  // Handlers would go here

  return (
    <div className="section credentials">      
      <input type="text" placeholder="Host Name" value={credentials.EmailServer} onChange={(e) => setCredentials({ ...credentials, EmailServer: e.target.value })} />
      <input type="email" placeholder="Email ID / User ID" value={credentials.UserID} onChange={(e) => setCredentials({ ...credentials, UserID: e.target.value })} />      
      <input type="password" placeholder="Password" value={credentials.Password} onChange={(e) => setCredentials({ ...credentials, Password: e.target.value })} />      
      <input type="text" placeholder="Sender Name" value={credentials.FromName} onChange={(e) => setCredentials({ ...credentials, FromName: e.target.value })} />
      <button onClick={() => {                              
                                handleAddElement(credentials, 'credentials'); 
                                setCredentials({ EmailServer: '', UserID: '', Password: '', FromName: '' });                               
                                }} >Add</button>                                    
      {addresult && <p className='add-result'>Credentials successfully added</p>}
      {adderror && <p className='add-error'>{adderror}</p>}            
    </div>
  );
};

export default Credentials;