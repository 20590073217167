import React, { useState, useEffect } from 'react';
import './EmailFormPage.css';
import axios from '../axiosConfig';
const EmailFormPage = () => {
  const [senders, setSenders] = useState(null);
  const [emailTemplate, setEmailTemplate] = useState({
    emailStyle: 'both', // 'formal', 'informal', or 'both'
    sender: '',
    replyAddress: '',
    BodyFormal: '',
    FooterFormal: '',
    BodyInformal: '',
    FooterInformal: '',
  });


  useEffect(() => {
    // Fetch Senders
    const fetchSenders = async () => {
      const response = await axios.get('/api/senders');
      setSenders(response.data);
    };

    // Fetch Template
    const fetchTemplates = async () => {
      const response = await axios.get('/api/email-template');
      setEmailTemplate(prevState => ({
        ...prevState,
        BodyFormal: response.data.BodyFormal,
        FooterFormal: response.data.FooterFormal,
        BodyInformal: response.data.BodyInformal,
        FooterInformal: response.data.FooterInformal,        
      }));
    };
  
    fetchSenders();
    fetchTemplates();    
  }, []);

  async function handleSubmit(e) {    
    e.preventDefault();    
    const response = await axios.post('/api/update-email-template', emailTemplate);
    console.log(response);
  }

  const onUpdate = (e) => {
    setEmailTemplate(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  

  return (
    <form onSubmit={handleSubmit}>
    <div className="email-template-container">
      <div className="form-header">
        <h1>Email Templates</h1>
      </div>            
      
      {/*<div className="reply-address-input">
        <label htmlFor="replyAddress">Different Reply Address: </label>
        <input
          type="email"
          name="replyAddress"
          value={emailTemplate.replyAddress}
          onChange={onUpdate}
        />
      </div>
    */}
      <div className="template-sections">
        <div className="informal-section">
          <h2>Informal Email</h2>
          <p>Liebe / r [Name]</p>
          Email Body
          <textarea
            placeholder="Body of the informal email"
            name="BodyInformal"
            value={emailTemplate.BodyInformal}
            onChange={onUpdate}
          />
          Email Footer
          <textarea
            placeholder="Footer of the informal email"
            name="FooterInformal"
            value={emailTemplate.FooterInformal}
            onChange={onUpdate}
          />
        </div>
        <div className="formal-section">
          <h2>Formal Email</h2>
          <p>Sehr geehrter Hr./Fr./… [Tile] [Sirname] [Post Title]</p>
          Email Body
          <textarea
            placeholder="Body of the formal email"
            name="BodyFormal"
            value={emailTemplate.BodyFormal}
            onChange={onUpdate}
          />
          Email Footer
          <textarea
            placeholder="Footer of the formal email"
            name="FooterFormal"
            value={emailTemplate.FooterFormal}
            onChange={onUpdate}
          />
        </div>
      </div>
      <button type="submit">Save Templates</button>
    </div>
    </form>
  );
};

export default EmailFormPage;