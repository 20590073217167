import React, { useState } from 'react';
import axios from '../axiosConfig';

// Define styles
const containerStyle = {
    maxWidth: '500px',
    margin: '40px auto',
    padding: '40px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
};

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
};

const inputStyle = {
    padding: '12px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '16px',
};

const buttonStyle = {
    padding: '12px 25px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
    width: '100%',
    backgroundColor: '#4CAF50',
    color: 'white',
};

const ChangePassword = () => {
    const [userId, setUserId] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [statusMessage, setStatusMessage] = useState(''); // State to hold password change status message

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/change-password', {
                userid: userId,
                oldPassword,
                newPassword
            });
            setStatusMessage('Password change successful');
            console.log('Password change successful', response.data);
            // Redirect or show a success message
        } catch (error) {
            setStatusMessage(error.response?.data || 'Password change failed due to an unexpected error.');
            console.error('Password change failed', error.response.data);
        }
    };

    return (
        <div className="change-password-container" style={containerStyle}>
            <form onSubmit={handleSubmit} style={formStyle}>
                <h2>Change Password</h2>
                <input
                    type="text"
                    placeholder="User ID"
                    value={userId}
                    onChange={(e) => {setUserId(e.target.value);
                                      setStatusMessage('');
                                     }}
                    style={inputStyle}
                />
                <input
                    type="password"
                    placeholder="Old Password"
                    value={oldPassword}
                    onChange={(e) => {setOldPassword(e.target.value);
                                      setStatusMessage('');}}
                    style={inputStyle}
                />
                <input
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => {setNewPassword(e.target.value);
                                      setStatusMessage('');}}
                    style={inputStyle}
                />
                <button type="submit" style={buttonStyle}>Change Password</button>
            </form>
            {statusMessage && <p>{statusMessage}</p>}
        </div>
    );
};

export default ChangePassword;
