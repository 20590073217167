import React, { useState } from 'react';
import useSearch from '../useSearch';
import SearchResults from '../SearchResults';
import AddElements from '../AddElements';

const Language = () => {
  const [Language, setLanguage] = useState({Language: ''});
  const { results, setResults: setSearchResults, errorMsg, setErrorMsg: setSearchError, handleSearch } = useSearch();
  const {addresult, setResults, adderror, setErrorMsg, handleAddElement } = AddElements();

  // Handlers would go here

  return (
    <div className="section gender">      
      <input type="text" placeholder="Language" value={Language.Language} onChange={(e) => setLanguage({ ...Language, Language: e.target.value})} />            
      <button onClick={() => {
                              setResults(null);
                              setErrorMsg(null);
                              handleSearch(Language, 'languages');                              
                              }}>Search</button>
      <button onClick={() => {
                                setSearchResults(null);
                                setSearchError(null);
                                handleAddElement(Language, 'languages');                                
                                }} >Add</button>                                    
      {addresult && <p className='add-result'>Language successfully added</p>}
      {adderror && <p className='add-error'>{adderror}</p>}

      {results && <SearchResults results={results} searchTable={'languages'} setResults={setSearchResults} />}
      {errorMsg && <p className='search-error'>{errorMsg}</p>}
    </div>
  );
};

export default Language;