import React from 'react';
import './StarRating.css'
// Star Component for displaying each star
const Star = ({ selected=false, onClick=() => {} }) => (
  <span className={selected ? "star selected" : "star"} onClick={onClick}>&#9733;</span>
);

// StarRating Component
const StarRating = ({ rating, onRatingChange }) => {
  return (
    <div>
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;
        return (
          <Star
            key={ratingValue}
            selected={ratingValue <= rating}
            onClick={() => onRatingChange(ratingValue)}
          />
        );
      })}
    </div>
  );
};

export default StarRating;