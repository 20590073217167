import React, { useState } from 'react';
import useSearch from '../useSearch';
import SearchResults from '../SearchResults';
import AddElements from '../AddElements';

const Productions = () => {
  const [production, setProduction] = useState({ location: '', name: '', year: '', month: '' });
  const { results, setResults: setSearchResults, errorMsg, setErrorMsg: setSearchError, handleSearch } = useSearch();
  const {addresult, setResults, adderror, setErrorMsg, handleAddElement } = AddElements();
  

  const years = Array.from({length: 55}, (_, index) => 1970 + index);
  const months = Array.from({length: 12}, (_, index) => index + 1);

  return (
    <div className="section productions">      
      <input type="text" placeholder="Location" value={production.location} onChange={(e) => setProduction({ ...production, location: e.target.value })} />
      <input type="text" placeholder="Name" value={production.name} onChange={(e) => setProduction({ ...production, name: e.target.value })} />
      <label htmlFor="year">Year:</label>
      <select id="year" value={production.year} onChange={(e) => setProduction({ ...production, year: e.target.value })}>
        {years.map((year) => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>
      <label htmlFor="month">Month:</label>
      <select id="month" value={production.month} onChange={(e) => setProduction({ ...production, month: e.target.value })}>
        {months.map((month) => (
          <option key={month} value={month}>{month}</option>
        ))}
      </select>
      <button onClick={() => {
                              setResults(null);
                              setErrorMsg(null);
                              handleSearch(production, 'productions');                              
                              }}>Search</button>
      <button onClick={() => {
                                setSearchResults(null);
                                setSearchError(null);
                                handleAddElement(production, 'productions');                                
                                }} >Add</button>                                    
      {addresult && <p className='add-result'>Production successfully added</p>}
      {adderror && <p className='add-error'>{adderror}</p>}

      {results && <SearchResults results={results} searchTable={'productions'} setResults={setSearchResults} />}
      {errorMsg && <p className='search-error'>{errorMsg}</p>}
    </div>
  );
};

export default Productions;