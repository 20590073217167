import React from 'react';

export const FilterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M3 4h18l-7 7v5l-4 4V11L3 4z" />
  </svg>
);

export const GearIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path fill="currentColor" d="M19.43 12.98l2.1-1.65-2.58-4.44-2.75.73a7.04 7.04 0 0 0-2.05-1.18l-.5-2.86h-5l-.5 2.86a7.04 7.04 0 0 0-2.05 1.18l-2.75-.73-2.58 4.44 2.1 1.65a6.94 6.94 0 0 0 0 2.04l-2.1 1.65 2.58 4.44 2.75-.73c.64.44 1.3.78 2.05 1.18l.5 2.86h5l.5-2.86c.75-.4 1.41-.74 2.05-1.18l2.75.73 2.58-4.44-2.1-1.65a6.94 6.94 0 0 0 0-2.04zM12 15.5a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7z"/>
    </svg>
);

export const AddIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path fill="currentColor" d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6v-2z"/>
    </svg>
);

export const EditIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path fill="currentColor" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
    </svg>
);

export const EmailIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path fill="currentColor" d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"/>
    </svg>
);

export const LockIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12 17a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm6-6V9c0-3.31-2.69-6-6-6S6 5.69 6 9v2c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2zm-1.9 0H7.9V9c0-2.21 1.79-4 4-4s4 1.79 4 4v2z"/>
    </svg>
);