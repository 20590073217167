import React from 'react';
import './AdminPage.css'
import Instruments from './sections/Instruments';
import Productions from './sections/Productions';
import Socials from './sections/Socials';
import Credentials from './sections/Credentials';
import Gender from './sections/Gender';
import Language from './sections/Language';

function AdminPage() {    

  return (
    <div className="admin-page-container">
      <div className="left-section">        
        <div className="section instruments">
          <h2>Instruments</h2>
          <Instruments />          
        </div>

        {/* Productions Section */}
        <div className="section productions">
          <h2>Productions</h2>
          <Productions />
        </div>

        {/* Social Media Section */}
        <div className="section social-media">
          <h2>Social Media</h2>
          <Socials />
        </div>
      </div>

      <div className="right-section">
        {/* Credentials Section */}
        <div className="section credentials">
          <h2>Credentials</h2>
          <Credentials />
        </div>

        {/* Gender Section */}
        <div className="section gender">
          <h2>Gender</h2>
          <Gender />
        </div>

        <div className="section gender">
          <h2>Language</h2>
          <Language />
        </div>

      </div>
    </div>
  );
}

export default AdminPage;