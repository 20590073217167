import React, { useState } from 'react';
import useSearch from '../useSearch';
import SearchResults from '../SearchResults';
import AddElements from '../AddElements';

const Gender = () => {
  const [gender, setGender] = useState({Sex:'', FormalTitle:'', InformalTitle:''});
  const { results, setResults: setSearchResults, errorMsg, setErrorMsg: setSearchError, handleSearch } = useSearch();
  const {addresult, setResults, adderror, setErrorMsg, handleAddElement } = AddElements();

  // Handlers would go here

  return (
    <div className="section gender">      
      <input type="text" placeholder="Gender" value={gender.Sex} onChange={(e) => setGender({ ...gender, Sex: e.target.value})} />
      <input type="text" placeholder="Formal Title" value={gender.FormalTitle} onChange={(e) => setGender({ ...gender, FormalTitle: e.target.value})} />
      <input type="text" placeholder="Informal Title" value={gender.InformalTitle} onChange={(e) => setGender({ ...gender, InformalTitle: e.target.value} )} />
      <button onClick={() => {
                              setResults(null);
                              setErrorMsg(null);
                              handleSearch(gender, 'gender');                              
                              }}>Search</button>
      <button onClick={() => {
                                setSearchResults(null);
                                setSearchError(null);
                                handleAddElement(gender, 'gender');                                
                                }} >Add</button>                                    
      {addresult && <p className='add-result'>Gender successfully added</p>}
      {adderror && <p className='add-error'>{adderror}</p>}

      {results && <SearchResults results={results} searchTable={'gender'} setResults={setSearchResults} />}
      {errorMsg && <p className='search-error'>{errorMsg}</p>}
    </div>
  );
};

export default Gender;