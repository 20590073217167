import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Navigate } from 'react-router-dom';

import './App.css'; // Make sure you have an App.css file in the src folder
import Header from './pages/Header';
import FilterPage from './pages/FilterPage';
import AdminPage from './pages/AdminPage';
import AddArtistPage from './pages/AddArtistPage';
import EditArtistPage from './pages/EditArtistPage';
import EmailFormPage from './pages/EmailFormPage';
import Login from './pages/login';
import SignUp from './pages/SignUp';
import ChangePassword from './pages/ChangePassword';
import AccountActivation from './pages/AccountActivation';
import {useState} from 'react';
import Plusbutton from './pages/components/Plusbutton';
import { FilterIcon, GearIcon, AddIcon, EditIcon, EmailIcon, LockIcon } from './pages/components/allicons';

const isAuthenticated = () => {
  return !!localStorage.getItem('userToken'); // Simple check for example purposes
};

const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};


function App() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <Router>
      <div className="app-container">      
        <div className={`sidebar ${isSidebarVisible ? 'expanded' : 'contracted'}`}>
          <div className="plusbuttondiv">
            <Plusbutton onClick={toggleSidebar} className="plusbutton" hoverColor="#4CAF50"/>
          </div>          
          <NavLink to="/filter" className={({ isActive }) => (isActive ? 'link active' : 'link')}>{isSidebarVisible ? 'Musicians Filter' : <FilterIcon />}</NavLink>
          <NavLink to="/admin" className={({ isActive }) => (isActive ? 'link active' : 'link')}>{isSidebarVisible ? 'Admin Data' : <GearIcon />}</NavLink>
          <NavLink to="/add-artist" className={({ isActive }) => (isActive ? 'link active' : 'link')}>{isSidebarVisible ? 'Add Musician' : <AddIcon />}</NavLink>
          <NavLink to="/edit-artist" className={({ isActive }) => (isActive ? 'link active' : 'link')}>{isSidebarVisible ? 'Edit Musician' : <EditIcon />}</NavLink>
          <NavLink to="/email-form" className={({ isActive }) => (isActive ? 'link active' : 'link')}>{isSidebarVisible ? 'Email Template' : <EmailIcon />}</NavLink>
          <NavLink to="/change-password" className={({ isActive }) => (isActive ? 'link active' : 'link')}>{isSidebarVisible ? 'Change Password' : <LockIcon />}</NavLink>
        </div>
        <div className="content">
          <Header />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/change-password" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
            <Route path="/filter" element={<PrivateRoute><FilterPage /></PrivateRoute>} />
            <Route path="/admin" element={<PrivateRoute><AdminPage /></PrivateRoute>} />
            <Route path="/add-artist" element={<PrivateRoute><AddArtistPage /></PrivateRoute>} />
            <Route path="/edit-artist" element={<PrivateRoute><EditArtistPage /></PrivateRoute>} />
            <Route path="/email-form" element={<PrivateRoute><EmailFormPage /></PrivateRoute>} />
            <Route path="/" element={<PrivateRoute><FilterPage /></PrivateRoute>} />
            <Route path="*" element={<Navigate to={isAuthenticated() ? "/" : "/login"} />} />
            <Route path="/verify/:token" element={<AccountActivation />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;