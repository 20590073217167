import { useState } from 'react';
import axios from '../axiosConfig';

const AddElements = () => {
    const [addresult, setResults] = useState(null);
    const [adderror, setErrorMsg] = useState(null);
    let validValues = true;
    const handleAddElement = async (elementNames, elementTable) => {                
        const elementValues = Object.values(elementNames);                
        elementValues.forEach(elementValue => {            
            console.log('Element value:', elementValue);
            if (!elementValue.trim() || elementValue === '') {
                setErrorMsg("Please enter valid text.");
                setResults(null);
                validValues = false;
            } else {
                console.log('without trim');
            }
        }); 

        if (!validValues) {
            return;
        }
            

        let columnNames;
        switch (elementTable) {
            case 'instruments':
                columnNames = ['InstrumentName'];
                break;
            case 'productions':
                columnNames = ['Location', 'Name', 'Year', 'Month'];
                break;
            case 'gender':
                columnNames = ['Sex', 'FormalTitle', 'InformalTitle'];
                break;
            case 'credentials':
                columnNames = ['EmailServer' , 'UserID', 'Password', 'FromName'];
                break;
            case 'socials':
                columnNames = ['URL', 'Icon'];
                break;
            case 'languages':
                columnNames = ['Language'];
                break;
            default:
                setErrorMsg("Invalid search table");
                return;
        }
        try {            
            const response = await axios.post('/api/add-to-database', {elementTable, columnNames, elementValues});
            if (response.data) {
                if (response.data.success) {
                    setResults(response.data.success);
                    setErrorMsg(null);
                } else {
                    setResults(null);
                    setErrorMsg(response.data.message);
                }
            }
            //setResults(response.data?.success || null);
            //setErrorMsg(null);
        } catch (error) {
            console.error('Error fetching data:', error);
            setResults(null);
            setErrorMsg('Incorrect parameters');
        }
    };

    return { addresult, adderror, setResults, setErrorMsg, handleAddElement };
};

export default AddElements;