import React from 'react';
import './SearchResults.css'
import axios from '../axiosConfig';
const SearchResults = ({ results, searchTable, setResults }) => {  
    console.log('Search table: ', searchTable);          
    function searchColName(searchTable) {
        console.log('table searched:' , searchTable);
        if (searchTable === 'instruments') {
            return 'InstrumentName';
        } else if (searchTable === 'productions') {            
            return 'Name';            
        } else if (searchTable === 'gender') {
            return 'Sex';        
        } else if (searchTable === 'socials') {
            return 'URL';
        } else if (searchTable === 'languages') {
            return 'Language';
        }
    }

    /*
    function onEdit(id) {
        console.log('Edit', id);
    }
    */

    async function onDelete(id) {
        const confirm = window.confirm('Are you sure you want to delete this item?');
        if (confirm) {
            const delResponse = await axios.post('/api/delete-from-database', {searchTable, id});            
            if (delResponse.data) {
                if (delResponse.data.success) {                    
                    const newResults = results.filter((result) => result.ID !== id);
                    setResults(newResults);                                    
                } else {
                    window.alert('Error deleting item');
                }
            }            
        }        
    }
    
    return (    
    <div className="search-results-container">
        {results?.map((result) => (
        <div key={result.ID} className="search-result-item">
            <span>{result[searchColName(searchTable)]}</span>
            {/*<button onClick={() => onEdit(result.ID)}>Edit</button>*/}
            <button onClick={() => onDelete(result.ID)}>Delete</button>
        </div>
        ))}        
    </div>
    );
};

export default SearchResults;