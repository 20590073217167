import React, { useState } from 'react';

const Plusbutton = ({
    size = 24,
    defaultColor = 'black',
    hoverColor = 'blue',
    className='',
    onClick,
  }) => {
    const [color, setColor] = useState(defaultColor);
  
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onMouseEnter={() => setColor(hoverColor)}
        onMouseLeave={() => setColor(defaultColor)}
        style={{ cursor: 'pointer' }} // Add cursor style for better UX
        className={className}
        onClick={onClick}
      >
        <rect width="24" height="2" x="0" y="5" rx="1" fill={color} />
        <rect width="24" height="2" x="0" y="11" rx="1" fill={color} />
        <rect width="24" height="2" x="0" y="17" rx="1" fill={color} />
      </svg>
    );
  };

export default Plusbutton;