import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const AccountActivation = () => {
    const { token } = useParams();
    const [message, setMessage] = useState('');
    
    const fetchPath = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001';
    useEffect(() => {
        fetch(`${fetchPath}/verify/${token}`)
        .then(response => {
            if (response.ok) {
                return response.text(); // Assuming the response is plain text
            }
                throw new Error('Network response was not ok.');
            })
            .then(data => setMessage(data))
            .catch(() => setMessage('There was a problem with the activation link.'));
    }, [token]);

    return (
        <div>
        <p>{message}</p>
        </div>
    );
};

export default AccountActivation;