import { useState  } from 'react';
import axios from '../axiosConfig';

const useSearch = () => {
    const [results, setResults] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    const handleSearch = async (searchTerms, searchTable) => {                
        let columnNames;
        switch (searchTable) {
            case 'instruments':
                columnNames = ['InstrumentName'];
                break;
            case 'productions':
                columnNames = ['Location', 'Name', 'Year', 'Month'];
                break;
            case 'gender':                
                columnNames = ['Sex'];
                break;
            case 'socials':
                columnNames = ['URL', 'Icon'];
                break;
            case 'languages':
                columnNames = ['Language'];
                break;
            default:
                setErrorMsg("Invalid search table");
                return;
        }

        try {
            //console.log('searchTable:', searchTable, 'columnNames:', columnNames, 'searchTerms:', searchTerms);
            const response = await axios.post('/api/search', { searchTable, columnNames, searchTerms });
            if (response.data) {
                if (response.data.length === 0) {
                    setResults(null);
                    setErrorMsg('No results found');
                } else {
                    setResults(response.data);    
                    //console.log('Results:', response.data);                
                    setErrorMsg(null);
                }                
            } else {
                setResults(null);

            }            
            //
        } catch (error) {
            console.error('Error fetching data:', error);
            setResults(null);
            setErrorMsg('Incorrect search parameters');
        }
    };


    return { results, setResults, errorMsg, setErrorMsg, handleSearch };
};

export default useSearch;