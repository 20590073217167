// src/axiosConfig.js
import axios from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001';

const instance = axios.create({
  baseURL: API_BASE_URL,
});

export default instance;
